<template>
  <!-- begin::kb-header -->
  <header class="kb-header" id="kb-header">
    <div class="header-component">
      <!-- header-title -->
      <div class="header-column header-title">
        연수원 이용 신청
      </div>
      <!-- //header-title -->
      <!-- header-util -->
      <div class="header-column header-util left">
        <div class="util util-back">
          <a href="javascript:" class="util-actions util-actions-back" @click="goBack">
            <i class="icon-history-back"></i>
          </a>
        </div>
      </div>
      <!-- //header-util -->
    </div>
  </header>
  <!-- end::kb-header -->

  <!-- begin::kb-main -->
  <main class="kb-main" id="kb-facility">

    <!-- main-content -->
    <div class="main-content main-component">

      <div class="educourse-view-container course-cancel">
        <div class="view-contents">
          <section class="content-section">
            <div class="section-content">
              <div class="content-item bd-clear">
                <div class="title">신청자 정보</div>
                <div class="desc">
                  <p>{{ session.deptNm }} ・ {{ session.jbgdNm }} ・ {{ session.lrnerNm }}</p>
                </div>
              </div>
              <div class="content-item">
                <div class="title">연수원</div>
                <div class="desc">
                  <p>{{ venue == '1' ? '속초연수원' : '대천연수원'}}</p>
                </div>
              </div>
              <div class="content-item">
                <div class="title">입소일</div>
                <div class="desc">
                  <p>{{ startDt.ymdformat }} ({{ getDayOfWeek(startDt.ymdformat) }})</p>
                </div>
              </div>
            </div>
            <div class="section-content">
              <div class="content-item">
                <div class="title">숙식형태</div>
                <div class="chk-cont">
                  <label for="chk_1" class="item-agree" v-if="venue == 2">
                    원룸
                    <div class="kb-form-check">
                      <input type="radio" id="chk_1" name="chk_cancel" class="kb-form-check-input" value="OR" v-model="room">
                    </div>
                  </label>

                  <label for="chk_2" class="item-agree">
                    투룸
                    <div class="kb-form-check">
                      <input type="radio" id="chk_2"  name="chk_cancel" class="kb-form-check-input" value="TR" v-model="room">
                    </div>
                  </label>

                  <label for="chk_3" class="item-agree">
                    안식년(투룸)
                    <div class="kb-form-check">
                      <input type="radio" id="chk_3" name="chk_cancel" class="kb-form-check-input" value="LTR" v-model="room">
                    </div>
                  </label>
                </div>
              </div>
              <div class="content-item">
                <div class="title">이용기간 선택</div>
                <div class="desc">

                  <div class="kb-form-dropdown" :class="{'is-active' : usePeridToggle}" data-offset="bottom-left">
                    <button class="kb-form-dropdown-btn" @click="usePeridToggle=!usePeridToggle"><span class="text">{{ usePeriodTxt }}</span><i class="icon-arrow"></i></button>

                    <div class="kb-form-dropdown-options" style="width: 358px;">
                      <div class="dropdown-option">
                        <ul class="dropdown-option-list">
                          <li class="dropdown-option-item" v-for="item in usePeridOption" :key="item.value">
                            <a href="javascript:" class="dropdown-option-link" @click="selectUsePeriod(item)">
                              <span class="dropdown-option-text">{{ item.label }}</span>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <div class="page-buttons"><button class="kb-btn kb-btn-primary" :disabled="!nextCheck()" @click="nextStep">다음</button></div>
    </div>
    <!-- //main-content -->
  </main>
  <!-- end::kb-main -->
</template>
<script>
import {computed, onMounted, ref, watch} from 'vue';
import {useStore} from "vuex";
import {lengthCheck, timestampToDateFormat} from "@/assets/js/util";

import {useRouter} from "vue-router";
import {ACT_SET_TRNCT_APLY_MOBILE_INFO} from "@/store/modules/trnct/trnct";
import {ACT_GET_TRNCT_OPER_YMD_CHECK} from "@/store/modules/trnct/trnct";

export default {
  name: 'DailyMobileApplyStep1',
  components: {

  },
  setup() {
    const store = useStore();
    const router = useRouter();

    const session = computed(() => store.state.auth.session);
    const startDt = computed(() => store.state.trnct.mobileApplyInfo.startDt);

    const isPersonal = ref(true);
    const reserveVenue = ref('연수원');
    const venue = ref(store.state.trnct.mobileApplyInfo.venue);
    const room = ref(store.state.trnct.mobileApplyInfo.room);
    const usePeridOption = ref([]);
    const usePeridToggle = ref(false);
    const usePerid = ref(store.state.trnct.mobileApplyInfo.usePerid);
    const stbyYn = ref(store.state.trnct.mobileApplyInfo.stbyYn);
    const usePeriodTxt = ref(store.state.trnct.mobileApplyInfo.usePeriodTxt);
    const usePeriodTxt2 = ref(store.state.trnct.mobileApplyInfo.usePeriodTxt2);

    watch(() => room.value, () => {
      //store.commit(`apply/${ACT_SET_APPLY_MOBILE_DATE}`, {room: room.value});

      usePeriodTxt.value = '숙박날짜';
      usePerid.value = null;
      stbyYn.value = 'N';
      getTrnctOperYmdCheck();
    })

    onMounted(()=> {
      getTrnctOperYmdCheck();
    })

    const getDateFormat = (format, value) => {
      if(value){
        return timestampToDateFormat(value, format ? format : 'yyyy.MM.dd');
      }
      return '-';
    };

    const nextStep = () => {
      store.commit(`trnct/${ACT_SET_TRNCT_APLY_MOBILE_INFO}`, {usePerid: usePerid.value, stbyYn: stbyYn.value, room: room.value, usePeriodTxt: usePeriodTxt.value, usePeriodTxt2: usePeriodTxt2.value, roomTy: room.value });
      router.push('/daily/apply/step2');
    }

    const getTrnctOperYmdCheck = async () => {
      await store.dispatch(`trnct/${ACT_GET_TRNCT_OPER_YMD_CHECK}`,{
        trnctMstSn : store.state.trnct.mobileApplyInfo.venue,
        rsvtAplyDd : store.state.trnct.mobileApplyInfo.startDt.ymdformat,
        prsnBrcYn : isPersonal.value === true ? "Y" : "N",
        roomTy : room.value
      }).then(res => {
        if(lengthCheck(res)){
          console.log('getTrnctOperYmdCheck...',res);
          usePeridOption.value = [];
          for(let i = 0 ; i < res.items.length ;i++){
            usePeridOption.value.push(res.items[i]);
          }
          //usePeridOption.value.push({label: '1박 2일 - 05/25(수요일 퇴소) -> 신청가능', value: '1'});


        }
      }).catch((e) => {console.error(e);})
    }

    const selectUsePeriod = (usePeriod) => {
      console.log("usePeriod",usePeriod)

      if(usePeriod.value == 0){
        return;
      }

      usePeriodTxt.value = usePeriod.label;
      usePeriodTxt2.value = usePeriod.label2;
      usePeridToggle.value = !usePeridToggle.value;
      usePerid.value = usePeriod.value;
      stbyYn.value = usePeriod.stbyYn;
    }

    const nextCheck = () => {
      return usePerid.value === null ? false : true;
    }

    const goBack = () => {
      router.go(-1);
    }

    const getDayOfWeek = (_date) => {
      const week = ['일', '월', '화', '수', '목', '금', '토'];
      const dayOfWeek = week[new Date(_date).getDay()];
      return dayOfWeek;
    }


    return {
      venue,
      room,
      reserveVenue,
      session,
      getDateFormat,
      nextCheck,
      nextStep,
      startDt,
      usePeridOption,
      getTrnctOperYmdCheck,
      usePeridToggle,
      selectUsePeriod,
      usePerid,
      stbyYn,
      usePeriodTxt,
      usePeriodTxt2,
      goBack,
      getDayOfWeek
    };
  },
};
</script>
